import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from 'react-router';
import {AUTOFILL_PAYMENT_DATA, BANKS_DEBTOR_ACCOUNT, CURRENCIES, PAYMENTS_BODY} from "../../../lib/constants";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEraser, faPencilAlt} from "@fortawesome/free-solid-svg-icons";

const PaymentFormComponent = (props) => {
    const {location, bankId, disableConnectBtn, arrangePaymentBody, scope} = props;
    const [autofillForm, setAutofillForm] = useState(false);
    const [fillObj, setFillObj] = useState(null);
    const [consent, setConsent] = useState('noConsent');
    const [domesticOrInternational, setDomesticOrInternational] = useState('domestic');

    const formRef = useRef();
    const textAreaRef = useRef();

    useEffect(() => {
        resetFormFields();
    }, [location.pathname]);

    useEffect(() => {
        setAutofillForm(!!AUTOFILL_PAYMENT_DATA[bankId]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankId]);


    useEffect(() => {
        arrangeFormFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fillObj, scope]);


    const arrangeFormFields = () => {
        let formData = new FormData(formRef.current);
        let hasAllFields = !!formData.get("creditorAccountIban") && !!formData.get("creditorName") &&
            !!formData.get("endToEndId") && !!formData.get("amount") && !!formData.get("currency") &&
            !!formData.get("remittanceInformation") &&
            !!(BANKS_DEBTOR_ACCOUNT.includes(bankId) ? formData.get("debtorAccountIban") : true);
        disableConnectBtn(!hasAllFields && scope === 'payment');
        if (hasAllFields) {
            const data = {
                creditorAccountIban: formData.get("creditorAccountIban"),
                creditorName: formData.get("creditorName"),
                endToEndId: formData.get("endToEndId"),
                amount: formData.get("amount"),
                currency: formData.get("currency"),
                remittanceInformation: formData.get("remittanceInformation"),
                ...(BANKS_DEBTOR_ACCOUNT.includes(bankId) &&
                    {debtorAccountIban: formData.get("debtorAccountIban")})
            }
            arrangePaymentBody(PAYMENTS_BODY(bankId, data));
        }
    }

    const fillFormFields = () => {
        resetFormFields();
        let fillObj;
        if (bankId === 'gr.piraeus') {
            fillObj = AUTOFILL_PAYMENT_DATA[bankId][consent ? consent : 'noConsent']
        } else {
            fillObj = AUTOFILL_PAYMENT_DATA[bankId][domesticOrInternational]
        }
        setFillObj(fillObj);
        disableConnectBtn(false);
    }

    const clearFormFields = () => {
        resetFormFields();
        setFillObj(null);
        disableConnectBtn(true);
    }

    const defineConsent = (e) => {
        setConsent(e.target.value);
    }

    const defineDomesticOrInternationalPayment = (e) => {
        setDomesticOrInternational(e.target.value);
    }

    const resetFormFields = () => {
        setFillObj(null);
        formRef.current.reset();
        textAreaRef.current.defaultValue = '';

    }

    return (
        <React.Fragment>
            <div className='connect-to-provider__label'>
                {'Info'}
            </div>
            <div className='connect-to-provider__options'>
                <Button className='connect-to-provider__clear'
                        variant={'light'}
                        onClick={clearFormFields}>
                    <i>
                        <FontAwesomeIcon icon={faEraser}/>
                    </i>
                    <span>Clear</span>
                </Button>
                {bankId === 'gr.piraeus' ?
                    <div className='connect-to-provider__cases'>
                        <label>
                            Transfer between
                        </label>
                        <select name='consent' id='consent'
                                defaultValue={consent}
                                onChange={defineConsent}>
                            <option value='noConsent' label='Homogeneous accounts'>
                            </option>
                            <option value='withConsent' label='Heterogeneous accounts'>
                            </option>
                        </select>
                    </div> : null}
                {bankId !== 'gr.piraeus' && AUTOFILL_PAYMENT_DATA[bankId] !== undefined &&
                AUTOFILL_PAYMENT_DATA[bankId]['international'] !== undefined ?
                    <div className='connect-to-provider__cases'>
                        <select name='domestic-or-international' id='domestic-or-international'
                                defaultValue={domesticOrInternational}
                                onChange={defineDomesticOrInternationalPayment}>
                            <option value='domestic' label='Domestic Payment'>
                            </option>
                            <option value='international' label='International Payment'>
                            </option>
                        </select>
                    </div> : null}
                {autofillForm ?
                    <Button className='connect-to-provider__autofill'
                            variant={'info'}
                            onClick={fillFormFields}>
                        <i>
                            <FontAwesomeIcon icon={faPencilAlt}/>
                        </i>
                        <span>Autofill</span>
                        <b/>
                    </Button>
                    : null}
            </div>
            <form ref={formRef} className='payments__form'>
                <div className='payments__form--field'>
                    <label>Creditor Account Iban</label>
                    <input type='text'
                           id='creditorAccountIban'
                           name='creditorAccountIban'
                           onChange={arrangeFormFields}
                           defaultValue={fillObj ? fillObj.creditorAccountIban : null}
                    />
                </div>
                <div className='payments__form--field'>
                    <label>Creditor Name</label>
                    <input type='text'
                           id='creditorName'
                           name='creditorName'
                           onChange={arrangeFormFields}
                           defaultValue={fillObj ? fillObj.creditorName : null}
                    />

                </div>
                <div className='payments__form--field'>
                    <label>End-to-end Id</label>
                    <input type='text'
                           id='endToEndId'
                           name='endToEndId'
                           onChange={arrangeFormFields}
                           defaultValue={fillObj ? fillObj.endToEndId : null}
                    />
                </div>
                <div className='payments__form--field'>
                    <label>Amount</label>
                    <input type='number' step='0.01'
                           id='amount'
                           name='amount'
                           onChange={arrangeFormFields}
                           defaultValue={fillObj ? fillObj.amount : null}
                    />
                </div>
                <div className='payments__form--field'>
                    <label>Currency</label>
                    <select name="currency" id="currency"
                            value={fillObj ? fillObj.currency : undefined}>
                        {CURRENCIES.map((currency, idx) => {
                            return <option key={'currency-' + idx} label={currency} value={currency}/>
                        })}
                    </select>
                </div>
                {BANKS_DEBTOR_ACCOUNT.includes(bankId) ?
                    <div className='payments__form--field'>
                        <label>Debtor Account </label>
                        <input type='text'
                               id="debtorAccountIban"
                               name="debtorAccountIban"
                               onChange={arrangeFormFields}
                               defaultValue={fillObj ? fillObj.debtorAccountIban : null}
                        />
                    </div> : null
                }
                <div className='payments__form--field'>
                    <label>Remittance Information</label>
                    <textarea id="remittanceInformation"
                              name="remittanceInformation"
                              ref={textAreaRef}
                              rows="2"
                              cols="70"
                              placeholder='Max 70 characters'
                              maxLength="70"
                              onChange={arrangeFormFields}
                              defaultValue={fillObj ? fillObj.remittanceInformation : null}
                    >
                    </textarea>
                </div>
            </form>
        </React.Fragment>
    );
};

export const PaymentForm = withRouter(PaymentFormComponent);