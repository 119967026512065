export const setTmpBankObj = (bankObj) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: 'SET_BANK_OBJ',
            bankObj: bankObj
        });
        resolve(bankObj)
    });

export const updateProvidersTries = (newTries) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({
            type: 'UPDATE_PROVIDERS_TRIES',
            timesTriedToLoadProviders: newTries
        });
        resolve(newTries)
    });
