export function providersToObject(provArr) {
    let obj = {};
    for (let i = 0; i < provArr.length; ++i)
        obj[provArr[i].provider] = provArr[i];
    return obj;
}

export function convertToBase64(provider){
    return new Buffer(provider).toString('base64');
}

export function getOAuth2ReducerName(bankId) {
    return bankId.replace(".", "_").toUpperCase();
}

export function getOAuth2ReducerType(type, strToRemove) {
    return type.replace(strToRemove, "").toLowerCase();
}

export function checkIfNotEmptyObject(el) {
    return Object.keys(el).length > 0 && el.constructor === Object
}

export function checkIfArray(el) {
    return el.constructor === Array
}

export function defineScopeType(respData) {
    return respData && (
        (respData.payload && respData.payload.paymentId) ||
        (respData.type && respData.type === "payments"))
        ? 'payment': 'accounts';
}