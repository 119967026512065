
export default function oAuthReducer(state = {
    bankObj: null,
    connectToBank: {
        fetching: false,
        fetched: false
    }
}, action) {
    switch (true) {
        case action.type === "SET_BANK_OBJ": {
            return {
                ...state,
                bankObj: action.bankObj
            }
        }
        case action.type.startsWith(('GET_OAUTH')) && action.type.endsWith(('PENDING')): {
            return {
                ...state,
                connectToBank: {
                    fetching: true,
                    fetched: false
                }
            }
        }
        case action.type.startsWith(('GET_OAUTH')) && action.type.endsWith(('REJECTED')): {
            return {
                ...state,
                connectToBank: {
                    fetching: false,
                    fetched: false
                }
            }
        }
        case action.type.startsWith(('GET_OAUTH')) && action.type.endsWith(('FULFILLED')): {
            return {
                ...state,
                connectToBank: {
                    fetching: false,
                    fetched: true
                }
            }
        }
        default : {
            return state
        }
    }
}