import React from 'react';
import {Accordion, Card} from "react-bootstrap";
import {AccountContent} from "../content/AccountContent";


function AccordionCard(props) {
    const {list, type, bank, resetProvider} = props;

    return (
        <div className={"auth-list__accordion--wrapper"}>
            <Accordion defaultActiveKey={type + "-0"}>
                {Object.keys(list).map((key)=> {
                    return <Card key={key}>
                        <Accordion.Toggle as={Card.Header} eventKey={type + "-key-" + key}>
                            {list[key].name ? ("Name: " + list[key].name) :
                                list[key].ownerName ? ("Owner Name: " + list[key].ownerName) :
                                    "Type: " + (list[key].cashAccountType ? list[key].cashAccountType : list[key].details)}
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={type + "-key-" + key}>
                            <Card.Body>
                                <AccountContent account={list[key]} bank={bank} resetProvider={resetProvider}/>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                })}
            </Accordion>
        </div>
    );
}

export default AccordionCard;