export default function accountsReducer(state = {
    fetching: false,
    fetched: false
}, action) {
    switch (action.type) {
        case "GET_ACCOUNTS_PENDING": {
            return {
                ...state,
                fetching: true,
                fetched: false
            }
        }
        case "GET_ACCOUNTS_REJECTED": {
            return {
            ...state,
                fetching: false,
                fetched: false
            }
        }
        case "GET_ACCOUNTS_FULFILLED": {
            return {
                ...state,
                fetching: false,
                fetched: true
            }
        }
        default : {
            return state
        }
    }
}