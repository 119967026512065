import {makeRequest} from "../api/api-client";
import {convertToBase64, getOAuth2ReducerName} from "../../lib/utils";

const prefix =  process.env.MIDDLEWARE_URL;

export const getOAuthInitUrl = (bankId, scope, body) => {
    const state = convertToBase64(bankId);
    const reqParams = {state: state, scope: scope};
    const headers = {
        bankId: bankId,
        ...(bankId === 'gr.eurobank') && {'psu-ip-address' : '192.168.56.14'}
    };

    let config = {headers: headers, params: reqParams};
    return makeRequest(prefix + "/oauth", "POST", body, config, "GET_OAUTH_" + getOAuth2ReducerName(bankId));
};

export const authCodeToAccessToken = (bankId, code, state, token) => {
    const params = token ? {tokenId: token, state: state} : {code: code, state: state};
    const headers = {
        bankId: bankId,
        'content-type': 'application/json',
        ...(bankId === 'gr.eurobank') && {'psu-ip-address' : '192.168.56.14'}
    };
    let config = {headers: headers, params: params};
    return makeRequest(prefix + "/oauth2", "GET", null, config, "GET_OAUTH2_" + getOAuth2ReducerName(bankId));
};

export const authorizationExtraStep = (path, method, headers) => {
    let config = {headers: headers};
    let params = {
        path: path,
        method: method
    };
    return makeRequest(prefix + '/oauth2/extra/step', method, params, config, "GET_OAUTH2_EXTRA_STEP_" + getOAuth2ReducerName(headers.bankId));
};

export const authorizationFinalStep = (path, method, headers, prop, body) => {
    let config = {headers: headers};
    let params = {
        path: path,
        method: method,
        body: {[prop]: body}
    };
    return makeRequest(prefix + '/oauth2/final/step', method, params, config, "GET_OAUTH2_FINAL_STEP_" + getOAuth2ReducerName(headers.bankId));
};

export const afterScaRedirectStep = (bankId, state) => {
    const params = {state: state}
    let headers = {};
    let config = {headers: headers, params: params};
    return makeRequest(prefix + "/unuapi/redirect", "GET", null, config, "UNU_API_REDIRECT_" + getOAuth2ReducerName(bankId));
};