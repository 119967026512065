import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import {Toast} from "react-bootstrap";
import {
    faCheckCircle,
    faTimesCircle

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NotificationToast = (props) => {
    const [toastObj, setToastObj] =  useState({});
    const {state, dispatch} = useContext(AppContext);

    const handleClose = () => {
        dispatch({
            ...state,
            toast: {
                show: false,
                type: null,
                msg: null
            }
        });
    };

    useEffect(()=> {
        setToastObj(state.toast)
    }, [state.toast]);


    return (
        <Toast className={"notification-toast " + (toastObj.type)} onClose={handleClose} show={toastObj.show} delay={6000} autohide  >
            <Toast.Body>
                <span>
                    {toastObj.type === "success" ?
                        <FontAwesomeIcon icon={faCheckCircle} />:null}
                    {toastObj.type === "error" ?
                        <FontAwesomeIcon icon={faTimesCircle} />:null}
                </span>
                <span>{toastObj.msg}</span>
            </Toast.Body>
        </Toast>
    );
};
