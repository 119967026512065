import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../App";
import {withRouter} from "react-router-dom";
import store from "../../store";
import {useSelector} from "react-redux";
import {
    afterScaRedirectStep,
    authCodeToAccessToken,
    authorizationExtraStep,
    authorizationFinalStep
} from "../../services/oAuthService";
import {setTmpBankObj} from "../../actions/actions";
import {OTP_METHODS, PROVIDERS, PROVIDERS_PATHS} from "../../../lib/constants";
import localforage from "localforage";
import Accounts from "./Accounts";
import {Loader} from "../ui/Loader";
import {checkIfNotEmptyObject, defineScopeType} from "../../../lib/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faTimes} from "@fortawesome/free-solid-svg-icons";
import AuthDetails from "./AuthDetails";
import Payment from "./Payment";

const ConnectedToBankComponent = (props) => {
    const {location} = props;
    const {state, dispatch} = useContext(AppContext);
    const [bankObj, setBankObj] = useState({});
    const [codeParam, setCodeParam] = useState(null);
    const [stateParam, setStateParam] = useState(null);
    const [tokenIdParam, setTokenIdParam] = useState(null);
    const [fetchingAuth, setFetchingAuth] = useState(false);
    const [pendingAuth, setPendingAuth] = useState(false);
    const [otp, setOtp] = useState(null);
    const [otpInput, setOtpInput] = useState('');
    const [oAuthAccessGranted, setOAuthAccessGranted] = useState(false);
    const [scope, setScope] = useState(null);

    const oAuth = useSelector(state => state.oAuthReducer);


    useEffect(() => {
        if (oAuth.bankObj && oAuth.bankObj.initFetched) {
            props.history.push("/oauth2/" + PROVIDERS[oAuth.bankObj.id].idx);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const searchStr = window.location.search;
        const hashStr = window.location.hash;
        const urlParams = new URLSearchParams(searchStr ? searchStr : (hashStr ? hashStr.replace('#', '?') : ''));
        const codeParam = urlParams.get('code');
        const stateParam = urlParams.get('state');
        const tokenIdParam = urlParams.get('tokenId');
        const bankPath = props.match.params.bank;
        const currScope = (location && location.pathname) ? location.pathname.split("/")[2]: null

        if (codeParam) {
            setCodeParam(codeParam);
        }
        if (stateParam) {
            setStateParam(stateParam);
        }
        if (tokenIdParam) {
            setTokenIdParam(tokenIdParam);
        }

        if (currScope) {
            setScope(currScope);
        }

        if (bankPath && !codeParam && !stateParam && !tokenIdParam && currScope) {
            let bankId = PROVIDERS_PATHS[bankPath].provider;
            store.dispatch(setTmpBankObj({id: bankId, initFetched: false}));
            setBankObj({id: bankId, initFetched: false});
            localforage.getItem(bankId + '-oAuthAccessGranted-' + currScope).then(function (value) {
                if (value) {
                    setOAuthAccessGranted(true);
                } else {
                    setOAuthAccessGranted(false);
                }
            });
        }

        // used when coming back from sca Redirect like in Eurobank GR
        if (location && location.pathname.includes('unuapi/redirect')) {
            const urlParams = new URLSearchParams(location.search.toString().substring(1));
            const state = urlParams.get('state');
            oAuthAfterScaRedirectStep(oAuth.bankObj.id, state)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, window.location.search]);

    useEffect(() => {
        let notEmptyBankObj = oAuth && oAuth.bankObj && checkIfNotEmptyObject(oAuth.bankObj);
        if (notEmptyBankObj && oAuth.bankObj.id) {
            setBankObj(oAuth.bankObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oAuth.bankObj]);


    useEffect(() => {
        if (bankObj.id && !bankObj.initFetched && codeParam && stateParam && !oAuthAccessGranted && !tokenIdParam) {
            setPendingAuth(true);
            getToken(bankObj.id, codeParam, stateParam)
        }
        if (bankObj.id && !bankObj.initFetched && tokenIdParam && !oAuthAccessGranted && !codeParam && stateParam) {
            setPendingAuth(true);
            getToken(bankObj.id, null, stateParam, tokenIdParam)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bankObj, codeParam, stateParam, tokenIdParam]);


    useEffect(() => {
        setFetchingAuth(oAuth.connectToBank.fetching);
    }, [oAuth.connectToBank.fetching]);


    const oAuthAfterScaRedirectStep = (bankId, state) => {
        store.dispatch(afterScaRedirectStep(bankId, state)).then((resp) => {
            let currScope = defineScopeType(resp.value.data);
            if (resp.value.data.status === 'success') {
                setOauthIsSuccessful(bankId, currScope);
            } else {
                let errorMessage = "An error occurred, failed to authorize a connection to " + PROVIDERS[bankId].name;
                handleError("Sca Redirect Step returned failure.", bankId, errorMessage);
            }
        })
    };

    const setOauthIsSuccessful = (bankid, currScope) => {
        localforage.setItem(bankid + '-oAuthAccessGranted-' + currScope, true).then(function (value) {
            setOAuthAccessGranted(value);
            let redirect = PROVIDERS[bankid].idx;
            store.dispatch(setTmpBankObj(null));
            props.history.push("/oauth2/" + currScope + "/" + redirect);
            setPendingAuth(false);
        });
    };

    const getToken = (bank, code, stateParam, token) => {
        store.dispatch(authCodeToAccessToken(bank, code, stateParam, token)).then((resp) => {
            // TODO add a further condition below when funds will be added
            const currScope = defineScopeType(resp.value.data);
            const isPayment = currScope === 'payment';
            setScope(currScope);
            localforage.setItem(bank + '-oAuthAccessToken-' + currScope + (isPayment ? '-' + resp.value.data.payload.paymentId : ''), resp.value.data).then(function (value) {
                let bankObj = {id: bank, initFetched: true};
                setCodeParam(null);
                store.dispatch(setTmpBankObj(bankObj));
                const links = value._links;
                if (links) {
                    if (links.scaRedirect && links.scaRedirect.method === 'REDIRECT') {
                        window.location.href = links.scaRedirect.href;
                    } else {
                        let consentInit = links.consentInit;
                        let path = consentInit.href ? consentInit.href : "";
                        let method = consentInit.method ? consentInit.method : "";
                        let headers = consentInit.headers ? consentInit.headers : "";
                        store.dispatch(authorizationExtraStep(path, method, headers)).then((resp) => {
                            localforage.setItem(bank + '-oAuthExtraStep-' + currScope, resp.value.data).then(function (value) {
                                let otp = value.payload && value.payload.chosenScaMethod &&
                                value.payload.chosenScaMethod.authenticationType ?
                                    value.payload.chosenScaMethod.authenticationType : null;
                                if (otp) {
                                    setOtp(otp);
                                }
                            })
                        }, (err) => {
                            let errorMessage = "An error occurred, failed to authorize a connection to " + PROVIDERS[bankObj.id].name;
                            handleError(err, bankObj.id, errorMessage);
                        })
                    }
                } else {
                    setOauthIsSuccessful(bankObj.id, currScope)
                }
            });
        }, (err) => {
            let errorMessage = "An error occurred, failed to acquire token for " + PROVIDERS[bankObj.id].name;
            handleError(err, bankObj.id, errorMessage);
        });
    };

    const oAuthFinalStep = () => {
        setOtp(null);
        let bankId = oAuth.bankObj.id;
        localforage.getItem(bankId + '-oAuthExtraStep-' + scope).then(function (value) {
            if (value._links) {
                let links = value._links.scaAuthentication;
                let path = links.href;
                let method = links.method;
                let headers = links.headers;
                let otpProp = Object.keys(links.body)[0];
                store.dispatch(authorizationFinalStep(path, method, headers, otpProp, otpInput)).then((resp) => {
                    localforage.setItem(bankObj.id + '-oAuthFinalStep-' + scope, resp.value.data).then(function (value) {
                        setOtp(null);
                        let redirect = PROVIDERS[bankId].idx;
                        store.dispatch(setTmpBankObj(null));
                        props.history.push("/oauth2/" + scope + "/" + redirect);
                        localforage.setItem(bankObj.id + '-oAuthAccessGranted-' + scope, true).then(function (value) {
                            setOAuthAccessGranted(value);
                            setPendingAuth(false);
                        });
                    });
                }, (err) => {
                    let errorMessage = "An error occurred, failed to authorize a connection to " + PROVIDERS[bankObj.id].name;
                    handleError(err, bankObj.id, errorMessage);
                });
            }
        });
    };

    const resetProvider = (bankId) => {
        let keysToRemove = [bankId + '-oAuthAccessToken-accounts',
            bankId + '-oAuthAccessGranted-accounts',
            bankId + '-accounts',
            bankId + '-oAuthAccessToken-payment',
            bankId + '-oAuthAccessGranted-payment',
        ];
        keysToRemove.forEach(key => {
            localforage.removeItem(key).then(() => {
                store.dispatch(setTmpBankObj(null));
                let redirect = PROVIDERS[bankObj.id].idx;
                props.history.push("/oauth2/" + scope + "/" + redirect);
            })
        });
    };

    const handleError = (error, bankId, msg) => {
        dispatch({
            ...state,
            toast: {
                show: true,
                type: "error",
                msg: msg
            }
        });

        let redirect = PROVIDERS[bankId].idx;
        props.history.push("/connect/" + redirect);
        store.dispatch(setTmpBankObj(null));
    };

    return (
        <div className="oAuth__container">
            <div className="provider__info">
                {bankObj.id ?
                    <div className="provider__label">
                        <span>Status of connection to </span> <b>{PROVIDERS[bankObj.id].name}</b>
                        {scope ?
                            <i>{" - scope: " + scope + ""}</i>
                        :null}
                    </div> : null}
                {!otp && !fetchingAuth && !pendingAuth ?
                    <React.Fragment>
                        {/*// oAuthAccessGranted && !otp && !fetchingAuth && bankObj.id && !pendingAuth ?*/}
                        {!oAuthAccessGranted ?
                            <span className="oAuth__granted oAuth__granted--negative">Unauthorized
                            <i>
                                <FontAwesomeIcon icon={faTimes}/>
                            </i>
                        </span> :
                            <span className="oAuth__granted oAuth__granted--positive">Authorized
                            <i>
                                <FontAwesomeIcon icon={faCheck}/>
                            </i>
                        </span>
                        }
                    </React.Fragment> : null}
                {!oAuthAccessGranted && bankObj.id && pendingAuth ?
                    <React.Fragment>
                        <span className="oAuth__granted oAuth__granted--pending">Pending
                           <i>
                               <FontAwesomeIcon icon={faClock}/>
                           </i>
                        </span>
                    </React.Fragment> : null
                }
                {!oAuthAccessGranted && bankObj.id && fetchingAuth ?
                    <Loader/> : null
                }
                {otp ?
                    <div className="otp">
                        <label htmlFor="otp">
                            {OTP_METHODS[otp].message}
                        </label>
                        <input type="text" id="otp" name="otp"
                               className="otp__input"
                               onChange={(event) => {
                                   setOtpInput(event.target.value)
                               }}
                               value={otpInput}
                               placeholder={'PIN'}/>
                        <button className="otp__btn"
                                disabled={!otpInput || otpInput.trim() === ''}
                                onClick={oAuthFinalStep}>
                            <span>Send</span>
                        </button>
                    </div> : null
                }
            </div>
            {(oAuthAccessGranted && !otp && !fetchingAuth && !pendingAuth && scope === 'accounts') ?
                <AuthDetails bank={bankObj.id}/>
                : null}
            {(oAuthAccessGranted && !otp && !fetchingAuth && !pendingAuth && scope === 'accounts') ?
                <Accounts bank={bankObj.id} resetProvider={resetProvider}/> : null}
            {(oAuthAccessGranted && scope === 'payment') ?
                <Payment bank={bankObj.id} /> : null}
        </div>
    );
}

export const ConnectedToBank = withRouter(ConnectedToBankComponent);