import {makeRequest} from "../api/api-client";

const prefix =  process.env.MIDDLEWARE_URL;

export const getTransactionsByAccount = (headers, startDate, endDate, accountId) => {
    let params = {
        from: startDate,
        to: endDate,
        accountId: accountId
    };
    let config = {headers: headers, params: params};
    return makeRequest(prefix + "/transactions", "GET", null, config, "GET_TRANSACTIONS");
};


