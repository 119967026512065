import React, {useEffect, useState} from 'react';
import {CREDENTIALS, PROVIDERS} from "../../../lib/constants";
import store from "../../store";
import {useSelector} from "react-redux";
import {getOAuthInitUrl} from "../../services/oAuthService";
import {setTmpBankObj} from "../../actions/actions";
import {Table} from "react-bootstrap";
import {ScopeRadioSelection} from "../ui/ScopeRadioSelection";
import {PaymentForm} from "../ui/PaymentForm";


function ConnectToBank(props) {
    const [bank, setBank] = useState(null);
    const [scope, setScope] = useState ('accounts');
    const [scopesArr, setScopesArr] = useState ([]);
    const [disableConnectInPayment, setDisableConnectInPayment] = useState(false);
    const [paymentBody, setPaymentBody] = useState(null);

    const providers = useSelector(state => state.providersReducer);

    useEffect(() => {
        const bank = PROVIDERS[props.match.params.bank];
        if (!bank || !bank.provider) {
            props.history.push("/")
        } else {
            let selectedBankId = bank.provider;
            const providersList = providers.list.payload;
            let selectedBank;
            if (providers.list.fetched) {
                selectedBank = providersList[selectedBankId];
                setBank(selectedBank);
                setScopesArray(selectedBank);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.bank, providers.list]);


    const setScopesArray = (bankObj) => {
        const supportedOperations = bankObj.supportedOperations;
        const availableOperations = ['ACCOUNT', 'PAYMENT', 'FUNDS'];
        let arr = availableOperations.map((operation) => {
            return supportedOperations.filter(e => e.operation === operation).length > 0 ? operation: undefined;

        }).filter((s)=> {
            return s !== undefined;
        });
       setScopesArr(arr);
    }

    const selectScope = (scope) => {
        setScope(scope);
        setDisableConnectInPayment(scope === 'payment');
    }


    const connectToBank = (bankId) => {
        store.dispatch(getOAuthInitUrl(bankId, scope, paymentBody)).then((resp) => {
            let bankObj = {id: bankId, initFetched: false};
            store.dispatch(setTmpBankObj(bankObj)).then(() => {
                let response = resp.value.data;
                if (bankId === 'cy.hellenic') {
                    response = decodeURIComponent(response);
                }
                window.open(response, "_self");
            });
        }, (err) => {
            console.log(err)
        });
    };

    const disableConnectBtn =(disable)=> {
        setDisableConnectInPayment(disable);
    }

    const arrangePaymentBody =(body)=> {
        setPaymentBody(body);
    }


    return (
        <React.Fragment>
            {bank ?
                <div className="connect-to-provider">
                    {bank.logo ?
                        <img src={'data:image/png;base64,' + bank.logo}
                             alt={bank.provider + ' logo'}
                             height={60}
                             className='connect-to-provider__logo'
                        />:null}
                    {CREDENTIALS[bank.provider] ?
                        <div className='credentials'>
                            <div className='connect-to-provider__label'>
                                {'Credentials'}
                            </div>
                            <Table bordered size="sm" className="credentials__tbl">
                                <thead>
                                    <tr>
                                        <th>
                                            {"Username"}
                                        </th>
                                        <th>
                                            {"Password"}
                                        </th>
                                        <th>
                                            {"OTP"}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {CREDENTIALS[bank.provider].map((creds, idx) => {
                                    return <tr key={'bank-creds-row-' + idx}>
                                        <td>
                                            {creds.user}
                                        </td>
                                        <td>
                                            {creds.pw}
                                        </td>
                                        <td>
                                            {creds.otp ? creds.otp : '-'}
                                        </td>
                                    </tr>
                                })
                                }
                                </tbody>
                            </Table>
                        </div>:
                        <h5 className="credentials-info"><i>No credentials available at the moment</i></h5>
                    }
                    <div className='connect-to-provider__props'>
                        <div className='connect-to-provider__content'>
                            <ScopeRadioSelection scopes={scopesArr}
                                                 selectScope={selectScope}
                                                 selectedScope={scope}/>
                            <div className={'connect-to-provider__payments ' + (scope === 'payment' ? 'expanded' : 'collapsed')}>
                                <PaymentForm bankId={bank.provider}
                                             disableConnectBtn={disableConnectBtn}
                                             arrangePaymentBody={arrangePaymentBody}
                                             scope={scope}
                                />
                            </div>
                            <div className='connect-to-provider__connection'>
                                <div className='connect-to-provider__label'>
                                    {'Connect to'}
                                </div>
                                <button className={"connect-to-provider__btn " + (PROVIDERS[bank.provider].idx + "-cls")}
                                        disabled={disableConnectInPayment}
                                        onClick={() => {
                                            connectToBank(bank.provider);
                                        }}>
                                    <span>{PROVIDERS[bank.provider].name}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null}
        </React.Fragment>
    );
}

export default ConnectToBank;