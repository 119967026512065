import React, {useEffect, useState} from 'react';
import localforage from "localforage";
import {Accordion, Card} from "react-bootstrap";


function AuthDetails(props) {
    const {bank} = props;
    const [tokenData, setTokenData] = useState(null);
    const [scaData, setScaData] = useState(null);


    useEffect(() => {
        if (bank) {
            localforage.getItem(bank + '-oAuthAccessToken-accounts').then(function(value) {
                if (value) {
                    setTokenData(value)
                }
            });
            localforage.getItem(bank + '-oAuthExtraStep-payment').then(function(value) {
                if (value) {
                    setScaData(value)
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bank]);

    return (
        <div className="auth-list">
            {tokenData || scaData > 0 ?
                <React.Fragment>
                    <h3>
                        Authorization Details
                    </h3>
                    <div className={"auth-list__accordion--wrapper"}>
                        <Accordion defaultActiveKey={"token-0"}>
                            {tokenData ?
                                 <Card key={"token-key"}>
                                    <Accordion.Toggle as={Card.Header} eventKey={"token-key"}>
                                        {"Access Token"}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={"token-key"}>
                                        <Card.Body>
                                           <pre>
                                               {JSON.stringify(tokenData, undefined, 2)}
                                           </pre>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>:null}
                            {scaData ?
                                <Card key={"sca-key"}>
                                    <Accordion.Toggle as={Card.Header} eventKey={"sca-key"}>
                                        {"SCA Method"}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={"sca-key"}>
                                        <Card.Body>
                                               <pre>
                                                   {JSON.stringify(scaData, undefined, 2)}
                                               </pre>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>: null}
                        </Accordion>
                    </div>
                </React.Fragment>:null
            }
        </div>
    );
}

export default AuthDetails;