import {combineReducers} from "redux";
import providersReducer from "./providersReducer";
import oAuthReducer from "./oAuthReducer";
import accountsReducer from "./accountsReducer";
import transactionsReducer from "./transactionsReducer";


const appReducer = combineReducers({
    providersReducer: providersReducer,
    oAuthReducer: oAuthReducer,
    accountsReducer: accountsReducer,
    transactionsReducer: transactionsReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action)
};

export default rootReducer;