import {providersToObject} from "../../lib/utils";

export default function providersReducer(state = {
    timesTriedToLoadProviders: 0,
    list: {
        payload: null,
        fetching: false,
        fetched: false
    },
}, action) {
    switch (action.type) {
        case "GET_PROVIDERS_PENDING": {
            return {
                ...state,
                list: {
                    ...state.list,
                    fetching: true,
                    fetched: false
                }
            }
        }
        case "GET_PROVIDERS_REJECTED": {
            return {
                ...state,
                list: {
                    ...state.list,
                    fetching: false,
                    fetched: false
                }
            }
        }
        case "GET_PROVIDERS_FULFILLED": {
            return {
                ...state,
                list: {
                    ...state.list,
                    fetching: false,
                    fetched: true,
                    payload: providersToObject(action.payload.data)
                }
            }
        }
        case "UPDATE_PROVIDERS_TRIES": {
            return {
                ...state,
                timesTriedToLoadProviders: action.timesTriedToLoadProviders
            }
        }
        default : {
            return state
        }
    }
}