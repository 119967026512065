import React from 'react';

export const Loader = (props) => {
    const {msg, fixedWidth, fullWidth} = props;
    return (
        <div className={"loader" + (fixedWidth ? " fixed__width": "") + (fullWidth ? " full__width": "")}>
            <span>{msg ? msg : ""}</span>
            <div className = "lds-circle">
                <div></div>
            </div>
        </div>
    );
};