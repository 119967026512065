import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../../../App";
import {PROVIDERS} from "../../../lib/constants";
import Moment from "moment";
import { extendMoment } from 'moment-range';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faSearch} from "@fortawesome/free-solid-svg-icons";
import localforage from "localforage";
import store from "../../store";
import {getTransactionsByAccount} from "../../services/transactionsService";
import {useSelector} from "react-redux";
import {Loader} from "../ui/Loader";
import {checkIfNotEmptyObject} from "../../../lib/utils";


const moment = extendMoment(Moment);

export const AccountContent = (props) => {

    const {account, bank, resetProvider} = props;
    const [startDate, setStartDate] = useState(moment().subtract(1, 'year').toDate());
    const [endDate, setEndDate] = useState(moment().toDate());
    const [transactions, setTransactions] = useState({});
    const [transactionsFetching, setTransactionsFetching] = useState(false);
    const {state, dispatch} = useContext(AppContext);

    const transactionsList = useSelector(state => state.transactionsReducer);

    useEffect(() => {
        setTransactionsFetching(transactionsList.fetching)
    }, [transactionsList.fetching]);


    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    const leftPicker = React.useRef();

    const rightPicker = React.useRef();

    const clickPicker = (picker) => {
        if (picker === "left") {
            leftPicker.current.input.click();
        } else {
            rightPicker.current.input.click();
        }
    };

    const searchTransactions = (accountId) => {
        let startDateParam = moment(startDate).format('YYYY-MM-DD');
        let endDateParam = moment(endDate).format('YYYY-MM-DD');
        localforage.getItem(bank + '-oAuthAccessToken-accounts').then(function(value) {
            console.log('VALUE?', value.payload.accessToken.value)
            if (value) {
                let headers = {
                    "Authorization": value.payload.accessToken.value,
                    "bankId": value.payload.bankId,
                    "PSU-IP-Address": "1.2.3.4"
                };

                if (value.payload.consentId) {
                    headers["Consent-ID"] = value.payload.consentId;
                }
                store.dispatch(getTransactionsByAccount(headers, startDateParam, endDateParam, accountId)).then((resp)=> {
                    let result = resp.value.data.payload;
                    let currTransactions = result.transactions;
                    setTransactions(currTransactions);
                }, (err)=> {
                    resetProvider(bank);
                    dispatch({
                        ...state,
                        toast: {
                            show: true,
                            type: "error",
                            msg: "Token expired, please reconnect to " + PROVIDERS[bank].name}
                    });
                });
            }
        });
    };

    return (
        <div className="auth-list__account">
            <pre>{JSON.stringify(account, undefined, 2)}</pre>
            <div className="auth-list__transactions col-md-12">
                <label>{"Transactions: "}</label>
                <div className="auth-list__transactions--datepicker">
                    <blockquote>Select daterange: </blockquote>
                    <i className="calendar-left" onClick={()=> {
                        clickPicker("left")
                    }}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </i>
                    <DatePicker
                        ref={leftPicker}
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        onChangeRaw={handleDateChangeRaw}
                        popperPlacement="top"
                    />
                    <i className="calendar-right" onClick={()=> {
                        clickPicker("right")
                    }}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                    </i>
                    <DatePicker
                        ref={rightPicker}
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        onChangeRaw={handleDateChangeRaw}
                        popperPlacement="top"
                    />
                    <button className= "auth-list__transactions--btn"
                            disabled={!startDate || !endDate}
                            onClick={()=> {searchTransactions(account.resourceId)}}>
                        <span>Search</span>
                        <i>
                            <FontAwesomeIcon icon={faSearch} />
                        </i>
                    </button>
                </div>
                {!transactionsFetching && checkIfNotEmptyObject(transactions) ?
                    <div className="auth-list__transactions--result">
                        <h4>
                            Result
                        </h4>
                        <pre>{JSON.stringify(transactions, undefined, 2)}</pre>
                    </div>
                :null}
                {transactionsFetching ?
                    <Loader msg="Fetching transactions" fullWidth={true}/>:null
                }
            </div>
        </div>
    );
};