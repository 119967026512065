import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux'
import {NavLink} from "react-router-dom";
import {matchPath, withRouter} from 'react-router';
import Collapse from 'react-bootstrap/Collapse';
import store from "../../store";
import {getProviders} from "../../services/providersService";
import {PROVIDERS} from "../../../lib/constants";
import {updateProvidersTries} from "../../actions/actions";


const AsideNavComponent = (props) => {
    const [openBanks, setOpenBanks] = useState({});
    const [providersObj, setProvidersObj] = useState([]);
    const providers = useSelector(state => state.providersReducer);
    const {location} = props;

    useEffect(() => {
        if (!providers.list.payload && !providers.list.fetching && providers.timesTriedToLoadProviders < 1) {
            store.dispatch(updateProvidersTries(providers.timesTriedToLoadProviders + 1))
            store.dispatch(getProviders())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providers.list]);

    useEffect(() => {
        let bankPath = matchPath(location.pathname, {path: '/oauth2/accounts/:bank'}) ||
            matchPath(location.pathname, {path: '/oauth2/payment/:bank'}) ||
            matchPath(location.pathname, {path: '/connect/:bank'});
        if (bankPath && bankPath.isExact) {
            let openBanksObj = {'url': bankPath.params.bank}
            setOpenBanks(openBanksObj);
        } else {
            setOpenBanks({});
        }
    }, [location.pathname]);

    useEffect(() => {
        if (providers && providers.list && providers.list.fetched) {
            setProvidersObj(providers.list.payload)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providers.list.fetched, providers.list.payload]);

    const handleMenuToggle = (bankId) => {
        let openBanksObj = {...openBanks};
        if (openBanksObj[bankId]) {
            openBanksObj[bankId] = !!(openBanksObj['url'] && openBanksObj['url'] === bankId);
        } else {
            openBanksObj[bankId] = true;
        }
        setOpenBanks(openBanksObj)
    };
    return (
        <React.Fragment>
            <aside>
                <ul>
                    <li>
                        <div className="menu__item">
                            <span className="menu__item--text">Providers</span>
                        </div>
                        {providersObj ?
                            <ul id="bank-list">
                                {Object.keys(providersObj).map((key) => {
                                    let bank = providersObj[key];
                                    let bankId = PROVIDERS[bank.provider].idx;
                                    let bankName = PROVIDERS[bank.provider].name;
                                    let isOpen = openBanks[bankId] || openBanks['url'] === bankId;
                                    return <React.Fragment key={bankId + '-list-item'}>
                                        {key !== "cy.astrobank" ?
                                            <React.Fragment>
                                            <div className="menu__item"
                                                 onClick={() => {
                                                     handleMenuToggle(bankId)
                                                 }}
                                                 aria-controls="example-collapse-text"
                                                 aria-expanded={isOpen}>
                                                <span className="menu__item--text">{bankName}</span>
                                                <span className={"menu__item--symbol" + (isOpen ? " minus" : " plus")}>
                                                    {isOpen ? "-" : "+"}
                                                </span>
                                            </div>
                                            <li key={bank.provider + "-list-item"}>
                                                <Collapse in={isOpen} dimension="height">
                                                    <ul>
                                                        <li>
                                                            <NavLink
                                                                to={{
                                                                    pathname: "/connect/" + bankId
                                                                }}
                                                                activeClassName="__active"
                                                                className={"menu__item menu__item--inner"}
                                                                key={bank.id + "-connect-key"}>
                                                                <span>{"Connect"}</span>
                                                                <span className="inline-arrow right"/>
                                                            </NavLink>
                                                            <NavLink
                                                                to={{
                                                                    pathname: "/oauth2/accounts/" + bankId
                                                                }}
                                                                activeClassName="__active"
                                                                className={"menu__item menu__item--inner"}
                                                                key={bank.id + "-accounts-key"}>
                                                                <span>{"Account Information"}</span>
                                                                <span className="inline-arrow right"/>
                                                            </NavLink>
                                                            <NavLink
                                                                to={{
                                                                    pathname: "/oauth2/payment/" + bankId
                                                                }}
                                                                activeClassName="__active"
                                                                className={"menu__item menu__item--inner"}
                                                                key={bank.id + "-payment-key"}>
                                                                <span>{"Payment Information"}</span>
                                                                <span className="inline-arrow right"/>
                                                            </NavLink>
                                                        </li>
                                                    </ul>
                                                </Collapse>
                                            </li>
                                            </React.Fragment>
                                            :null}
                                    </React.Fragment>
                                })}
                            </ul> : null}
                    </li>
                </ul>
            </aside>
        </React.Fragment>
    );
}

export const AsideNav = withRouter(AsideNavComponent);
