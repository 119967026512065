import React, {useEffect, useReducer} from 'react';
import {Link, Redirect, Route, Switch, withRouter} from "react-router-dom";
import update from 'immutability-helper';
import localforage from "localforage";
import {AsideNav} from "./js/components/mainframe/AsideNav";
import ConnectToBank from "./js/components/content/ConnectToBank";
import {ConnectedToBank} from "./js/components/content/ConnectedToBank";
import Logo from './assets/img/avacas-logo.png';
import {Landing} from "./js/components/mainframe/Landing";
import {NotificationToast} from "./js/components/ui/NotificationToast";

export const AppContext = React.createContext();

const initialState = {
    toast: { show: false, type: null, msg: null}
};

function reducer(state, action) {
    return update(state, {
        toast: {$set: action.toast},
    });
}


const AppComponent = (props) =>  {
    const [state, dispatch] = useReducer(reducer, initialState);


    useEffect(() =>{
        localforage.config({
            name: 'avacasIndexedDB',
            storeName: 'keyvaluepairs',
            driver: localforage.INDEXEDDB
        });
    },[]);

      return (
        <div className="App">
            <AppContext.Provider value={{ state, dispatch }}>
              <header>
               <section>
                   <Link to="/" className="logo__link">
                       <img src={Logo} alt="Avacas Logo" className='logo__img'/>vacas
                   </Link>
               </section>
              </header>
              <main>
               <NotificationToast />
                <AsideNav/>
                  <section>
                    <Switch>
                        <Route exact path='/connect/:bank/' render={props => <ConnectToBank {...props} />}/>
                        <Route exact path="/" render={props => <Landing {...props} />}/>
                        <Route exact path='/oauth2' render={props => <ConnectedToBank{...props} />}/>
                        <Route exact path='/oauth2/accounts/:bank' render={props => <ConnectedToBank{...props} />}/>
                        <Route exact path='/oauth2/payment/:bank' render={props => <ConnectedToBank{...props} />}/>
                        <Route exact path='/unuapi/redirect' render={props => <ConnectedToBank{...props} />}/>
                        <Route path="/" render={() => <Redirect to="/"/>}/>
                    </Switch>
                  </section>
              </main>
            </AppContext.Provider>
        </div>
      );
};

export const App = withRouter(AppComponent);
