import {makeRequest} from "../api/api-client";

const prefix =  process.env.MIDDLEWARE_URL;

export const getAccounts = (headers) => {
    let config = {headers: headers};
    return makeRequest(prefix + "/accounts", "GET", null, config, "GET_ACCOUNTS");
};


