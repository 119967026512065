import React, {useEffect, useState} from 'react';

export const ScopeRadioSelection = (props) => {
    const {scopes, selectScope, selectedScope} = props;
    const [scope, setScope] = useState(selectedScope);


    useEffect(() => {
        setScope(selectedScope);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedScope]);

    const setSelectedScope =(e)=> {
        selectScope(e.target.value);
    }

    return (
        <div className='connect-to-provider__scopes'>
            <div className='connect-to-provider__label'>Scope</div>
            <form className='connect-to-provider__scopes--radio'
                  autoComplete='off'>
                <label>
                    <input type="radio"
                           value="accounts"
                           name="scope"
                           onChange={setSelectedScope}
                           checked={scope === 'accounts'}
                           disabled={!scopes.includes('ACCOUNT')}/>
                    <span>Accounts</span>
                </label>
                <label>
                    <input type="radio"
                           value="payment"
                           name="scope"
                           onChange={setSelectedScope}
                           checked={scope === 'payment'}
                           disabled={!scopes.includes('PAYMENT')}/>
                    <span>Payment</span>
                </label>
                <label>
                    <input type="radio"
                           value="funds"
                           name="scope"
                           onChange={setSelectedScope}
                           checked={scope === 'funds'}
                           disabled={!scopes.includes('FUNDS') || true}/>
                    <span>Funds</span>
                </label>
            </form>
        </div>
    );
};