import React, {useEffect, useState, useContext} from 'react';
import {AppContext} from "../../../App";
import localforage from "localforage";
import store from "../../store";
import {getAccounts} from "../../services/accountsService";
import AccordionCard from "../ui/AccordionCard";
import {useSelector} from "react-redux";
import {Loader} from "../ui/Loader";
import {PROVIDERS} from "../../../lib/constants";


function Accounts(props) {
    const {bank, resetProvider} = props;
    const [accounts, setAccounts] = useState([]);
    const [accountsFetching, setAccountsFetching] = useState(false);
    const {state, dispatch} = useContext(AppContext);
    const accountsList = useSelector(state => state.accountsReducer);

    useEffect(() => {
        setAccountsFetching(accountsList.fetching)
    }, [accountsList.fetching]);

    useEffect(() => {
        if (bank) {
            localforage.getItem(bank + '-oAuthAccessToken-accounts').then(function(value) {
                if (value) {
                    let headers = {
                        "Authorization": value.payload.accessToken.value,
                        "bankId": value.payload.bankId,
                        "Content-Type": "application/json",
                        "PSU-IP-Address": "1.2.3.4",
                        "PSU-ID" : "someid"
                    };

                    if (value.payload.consentId) {
                        headers["Consent-ID"] = value.payload.consentId;
                    }
                    store.dispatch(getAccounts(headers)).then((resp)=> {
                        let result = resp.value.data.payload;
                        let accounts = result.accounts ? result.accounts : result;
                        setAccounts(accounts);
                    }, (err)=> {
                        resetProvider(bank);
                        console.log(err.response.status);
                        dispatch({
                            ...state,
                            toast: {
                                show: true,
                                type: "error",
                                msg: "Token expired, please reconnect to " + PROVIDERS[bank].name}
                        });
                    });
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bank]);

    return (
        <div className="account auth-list">
            {accounts && accounts.length > 0 && !accountsFetching ?
                <React.Fragment>
                    <h3>
                        Accounts
                    </h3>
                    <AccordionCard list={accounts} type="account" bank={bank} resetProvider={resetProvider} />
                </React.Fragment>:null
            }
            {accountsFetching ?
                <Loader msg="Fetching accounts" fixedWidth={true}/>:null
            }
        </div>
    );
}

export default Accounts;