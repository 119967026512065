import React, {useEffect, useState} from 'react';
import localforage from "localforage";

function Payment(props) {
    const {bank} = props;
    const [paymentNoConsent, setPaymentNoConsent] = useState(null);
    const [paymentWithConsentStep1, setPaymentWithConsentStep1] = useState(null);
    const [paymentWithConsentStep2, setPaymentWithConsentStep2] = useState(null);
    const [isPiraeusGr, setIsPiraeusGr] = useState(false);


    useEffect(() => {
    }, []);

    useEffect(() => {
        if (bank) {
            localforage.keys().then(function(keys) {
                const currPayments = keys.filter((key)=> {
                    return key.startsWith(bank + '-oAuthAccessToken-payment');
                });
                currPayments.forEach((payment) => {
                    localforage.getItem(payment).then(function(value) {
                        if (value._links) {
                            setPaymentWithConsentStep1(value);
                        } else {
                            setPaymentNoConsent(value)
                        }
                    });
                });
                localforage.getItem(bank + '-oAuthExtraStep-payment').then(function(value) {
                    setPaymentWithConsentStep2(value)
                });
            });
        }
        setIsPiraeusGr(bank === 'gr.piraeus')
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bank]);

    return (
        <div className="payment">
            {paymentNoConsent ?
                <React.Fragment>
                    <h3>
                        {'Payment' + (isPiraeusGr ?  ' Homogeneous Accounts': '')}
                    </h3>
                    <pre>{JSON.stringify(paymentNoConsent, undefined, 2)}</pre>
                </React.Fragment>:null
            }
            {paymentWithConsentStep1 ?
                <React.Fragment>
                    <h3>
                        {'Payment - Heterogeneous Accounts' + (isPiraeusGr ?  ' - Step 1': '')}
                    </h3>
                    <pre>{JSON.stringify(paymentWithConsentStep1, undefined, 2)}</pre>
                </React.Fragment>:null
            }
            {paymentWithConsentStep2 ?
                <React.Fragment>
                    <h3>
                        {'Payment  - Heterogeneous Accounts - Step2'}
                    </h3>
                    <pre>{JSON.stringify(paymentWithConsentStep2, undefined, 2)}</pre>
                </React.Fragment>:null
            }
        </div>
    );
}

export default Payment;